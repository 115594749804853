html {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
}

body > div { flex: 1; }

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.button-generic {
  background-color:rgba(150, 0, 150, 0.1);
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: 1;
  padding: 0.5rem;
}

.bordered {
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: 1;
  padding: 0.25rem;
}

.button-generic:hover {
  background-color:rgba(150, 0, 150, 0.2)
}

.button-small {
  background-color:rgba(212, 212, 212, 0.1);
  border-width: 0px;
  border-radius: 0.25rem;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: 1;
  padding: 0.1rem;
}

.button-small:hover {
  background-color:rgba(150, 0, 150, 0.2)
}

.active-tab {
  background-color:rgba(150, 0, 150, 0.3);
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-radius: 0.25rem;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.inactive-tab {
  background-color:rgba(150, 0, 150, 0.15);
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-radius: 0.25rem;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.row-even {
  background-color:rgb(243 244 246);
}

.row-odd {

}